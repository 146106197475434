<template>
  <div id="quality-requirements-section">
    <v-card>
      <v-toolbar flat card dark color="accent">
        <v-toolbar-title><h1>{{ title }}</h1></v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>{{ body }}</p>
        <ol>
          <li v-for="(item, i) in items" :key="i">{{ item }}</li>
        </ol>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LegalStore from '../LegalStore.js'
export default {
  data: () => ({
    title: LegalStore.data.QualityRequirements.data.title,
    body: LegalStore.data.QualityRequirements.data.body,
    items: LegalStore.data.QualityRequirements.data.items
  })
}
</script>

<style scoped>

</style>