<template>
  <div id="markets">
    <MarketsPage/>
  </div>
</template>

<script>
import MarketsPage from '@/components/markets/MarketsPage.vue'
export default {
  data: () => ({

  }),
  components: {
    MarketsPage
  }
}
</script>