<template>
  <div id="services">
    <ServicesPage/>
  </div>
</template>

<script>
  import ServicesPage from '../components/services/ServicesPage.vue'
  export default {
    data: () => ({  }),
    components: {
      ServicesPage
    }
  }
</script>
