<template>
  <div id="nda">
    <v-card>
      <v-toolbar card flat dark color="accent">
        <v-toolbar-title><h1>{{ title }}</h1></v-toolbar-title>
      </v-toolbar>
      <v-card-text><p>{{ body }}</p></v-card-text>
    </v-card>
  </div>
</template>

<script>
import LegalStore from '../LegalStore.js'
export default {
  data: () => ({
    title: LegalStore.data.NDA.data.title,
    body: LegalStore.data.NDA.data.body
  })
}
</script>
