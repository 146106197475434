<template>
  <div id="legal">
    <HeroImage :imgSrc="image" :title="pageTitle"/>
    <v-container fluid grid-list-lg>
      <v-layout align-start row wrap>

        <v-flex xs12 sm10 offset-sm1> <CorpData/> </v-flex>
        <!-- <v-flex xs12 sm10 offset-sm1> <CapState/> </v-flex> -->

        <!-- <v-flex xs12 sm5 offset-sm1> <NDA/> </v-flex> -->
        <!-- <v-flex xs12 sm5 > <Patents/> </v-flex> -->
        
        <!-- <v-flex xs12 sm10 offset-sm1> <CorpResp/> </v-flex> -->
        <v-flex xs12 sm10 offset-sm1> <TermsCond/> </v-flex>

        <v-flex xs12 sm10 offset-sm1> <QualityReqs/> </v-flex>

        <v-flex xs12 sm10 offset-sm1> <Ethics/> </v-flex>
        
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import LegalStore from './LegalStore.js'
import HeroImage from '../layout/HeroImage.vue'
import CapState from './sections/CapState.vue'
import TermsCond from './sections/TermsCond.vue'
import CorpResp from './sections/CorpResp.vue'
import CorpData from './sections/CorpData.vue'
import NDA from './sections/NDA.vue'
import Patents from './sections/Patents.vue'
import Ethics from './sections/Ethics.vue'
import QualityReqs from './sections/QualityRequirements.vue'

export default {
  data: () => ({
    pageTitle: 'Corporate Governance',
    image: LegalStore.data.titleImg
  }),
  components: {
    HeroImage,
    CapState,      
    TermsCond,
    CorpResp,
    CorpData,
    NDA,
    Patents,
    Ethics,
    QualityReqs,
  }
}
</script>

<style scoped>
  #page-title {
    font-size: 60pt;
  }
</style>
