<template>
  <div id="ethics-statement">
    <v-card>
      <v-toolbar flat card dark color="accent">
        <v-toolbar-title><h1>{{ title }}</h1></v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>{{ description }}</p>
        <div v-for="(item, i) in items" :key="i">
          <h3>{{ item.name }}</h3>
          <p>{{ item.text }}</p>
        </div>
        <div v-for="(section, s) in sections" :key="s">
          <p>
            <b v-if="section.bold">{{ section.bold }}</b> {{ section.text }}
          </p>
        </div>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EthicsStore from '@/stores/EthicsStore.js'
export default {
  data: () => ({
    title: EthicsStore.title,
    description: EthicsStore.description,
    items: EthicsStore.items,
    sections: EthicsStore.sections
  }),
}
</script>

<style scoped>

</style>