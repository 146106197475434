<template>
  <div id="services-page">
    <HeroImage 
      :imgSrc="img" 
      :title="pageTitle" 
      :position="pos" 
      :height="heroHeight"
      class="hidden-md-and-down"
    />
    <HeroImage 
      :imgSrc="img" 
      :title="pageTitle" 
      :position="pos" 
      :height="heroHeight_sm"
      class="hidden-lg-and-up"
    />
    <v-card dark>
      <v-container fluid>
        <v-layout column justify-center align-content-center fill-height>
          
          <v-card-title> <h1>{{ competencies.title }}</h1> </v-card-title>
          
          <v-card-text>
            <ul v-for="(b, i) in competencies.body" :key="i">
              <li>{{ b }}</li>
            </ul>
          </v-card-text>
          
        </v-layout>
      </v-container>
    </v-card>
    <v-container grid-list-sm>
      <v-layout 
        align-start
        justify-center
        column
      >
        <v-flex xs12 sm6 v-for="(s,i) in services"
        :key="i">
          <v-card >
            <v-container>
              <v-layout column fill-height>
                <v-flex xs12>
                  <v-card-title id="service"><h1>{{ s.title }}</h1></v-card-title>
                
                  <v-card-text id="description"><p>{{ s.description }}</p></v-card-text>
                  <v-card-text>
                    <v-list>
                      <v-list-group
                        v-for="item in s.items"
                        :key="item.title"
                        v-model="item.active"
                        no-action
                      >
                        <template v-slot:activator>
                          <v-list-tile>
                            <v-list-tile-content>
                              <v-list-tile-title>examples</v-list-tile-title>
                            </v-list-tile-content>
                          </v-list-tile>
                        </template>
                        <v-list-tile
                          v-for="b in item.body"
                          :key="b"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title><i>{{ b }}</i></v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list-group>
                    </v-list>
                  </v-card-text>
                  <v-btn outline  :to="s.path">Details</v-btn>
                  <!-- <DetailsDialog :pageTitle="getTitle(s)" :description="getDescription(s)" :sections="getSections(s)" /> -->
                </v-flex>    
              </v-layout>
            </v-container>          
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import HeroImage from '../layout/HeroImage.vue'
import ServicesStore from '../../stores/ServicesStore.js'
import DisciplinesStore from '@/stores/DisciplinesStore.js'
import DetailsPage from '@/components/disciplines/DetailsPage.vue'
import DetailsDialog from '@/components/dialogs/DetailsDialog.vue'
export default {
  data: () => ({
    heroHeight: '400px',
    heroHeight_sm: '200px',
    pageTitle: ServicesStore.data.Services.data.title,
    services: ServicesStore.data.Services.data.items,
    competencies: ServicesStore.data.CoreCompetencies.data,
    img: 'https://images.unsplash.com/photo-1517420704952-d9f39e95b43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    pos: 'left top'
  }),
  components: {
    HeroImage,
    DetailsPage,
    DetailsDialog
  },
  methods: {
    
    // TODO: Solve how to display details page as a dialog instead of a separate page.
    
    getDiscipline: function(s) {
      switch(s) {
        case 'Electrical Engineering': return this.DisciplinesStore.data.ElectricalEngineering;
        case 'Mechanical Engineering': return this.DisciplinesStore.data.MechanicalEngineering;
        case 'Firmware/Software Development': return this.DisciplinesStore.data.FirmwareSoftwareEngineering;
        case 'Industrial Automation': return this.DisciplinesStore.data.IndustrialAutomation;
        case 'Process Support & Logistics': return this.DisciplinesStore.data.ProcessSupportLogistics;
      }
    },
    getTitle: function(s) {
      return this.getDiscipline(s).title;
    },
    getDescription: function(s) {
      return this.getDiscipline(s).description;
    },
    getSections: function(s) {
      return this.getDiscipline(s).sections;
    }
  }
}
</script>

<style scoped>
#page-title {
  font-size: 60pt;
}
#description {
  font-size: 16pt;
}
#service {
  font-size: 18px;
}
</style>
