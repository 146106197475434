const CapabilityStatement = {
  data: { 
    title: 'Capability Statement',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel.',
  },
  accessors: {  },
  mutators: {  }
};

const CorporateData = {
  data: { 
    title: 'Corporate Data',
    body: [
      'Established 2008',
      'HQ in Rapid City, SD',
      'PSSM Level: 3',
      'NAICS: 541330, 541512, 541715',
      'Cage: 7FXF2',
      'F16 ADG',
      'C5 Matchset',
      'B-1 FEB'
    ],
    items: [
      {
        title: 'Past Performance',
        body: [
          'F16 ADG',
          'C5 Matchset',
          'ANMST-T1V',
          'B-1 FEB'
        ]
      }
    ],
    imgs: [
      { name: 'Military Seals', path: require("@/assets/legal/military_seals.png")},
      { name: 'Veteran Owned Business', path: require("@/assets/legal/vet_owned_biz.png")},
      { name: 'AS9100:2016 Revision D', path: require("@/assets/legal/as9100.png")},
      { name: 'ITAR', path: require("@/assets/legal/itar.png")}
    ]
  },
  accessors: {  },
  mutators: {  }
};

const QualityRequirements = {
  data: {
    title: 'Quality Flow Down Requirements',
    body: `In compliance to AS9100D and ISO 13485, this agreement applies to all tooling, materials, components, subassemblies, and products manufactured for DARCEO INC.`,
    items: [
      `Supplier agrees that Customer’s print(s) and/or specification(s) shall be the controlling document regarding product quality.`,
      `Supplier agrees that Customer’s purchase orders shall be the controlling document regarding the product revision to be produced. If product revision discrepancies exist between Customer’s purchase orders and print and/or specification Supplier agrees to contact Customer prior to production of product. Customer agrees to notify Supplier of any revision changes and to provide amended purchase orders with appropriate prints and/or specifications.`,
      `Tooling - Liability for proper use and safeguards in the use of tooling shall be the responsibility of the supplier. The supplier shall also manage tool life and provide all preventive maintenance to ensure proper working order.`,
      `Darceo Inc.’s Customer reserves the right to perform an on-site audit at the Supplier’s premises of its quality system and the processes used to manufacture products for Darceo Inc.`,
      `Supplier agrees to notify Customer in advance of any proposed change in product specification, material composition and/or manufacturing process.`,
      `A Certificate of Compliance (C of C) shall be included with every lot shipped from Supplier to Customer. The C of C should list all lot #’s and/or date codes of products in the shipment. Copies of inspection/test records shall be available upon request.`,
      `Supplier quality records related to the production of Customer’s products as applicable must be retained permanently or as otherwise agreed upon by both parties. At the end of the retention period, Supplier shall contact Customer to determine the appropriate disposition of the records (e.g., send to Customer, destroy, etc.).`,
      `All equipment used to produce or measure product for Darceo is required to be qualified and calibrated per AS9100 Requirements.`
    ]
  },
  accessors: {},
  mutators: {},
};

const TermsAndConditions = {
  data: {
    title: 'Terms & Conditions',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel.'
  }
};

const CorporateResponsibility = {
  data: {
    title: 'Corporate Responsibility',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel.'
  }
};

const Patents = {
  data: {
    title: 'Patents',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel.'
  }
};

const NDA = {
  data: {
    title: 'Non-Disclosure Agreement (NDA)',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel.'
  }
};

const LegalStore = {
  data: {
    titleImg: 'https://images.unsplash.com/photo-1436450412740-6b988f486c6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    CapabilityStatement,    
    CorporateData,
    QualityRequirements,
    TermsAndConditions,
    CorporateResponsibility,
    Patents,
    NDA
  }
};

export default LegalStore;
