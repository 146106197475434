<template>
  <v-card>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 sm4>
          <v-img height="300" :src="imgSrc"></v-img>
        </v-flex>
        <v-flex xs12 sm8>
          <v-card min-height="300" flat>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-sheet color="accent" dark>
                    <v-card-title><h3>{{ title }}</h3></v-card-title>
                  </v-sheet>
                </v-flex>
            <v-card-text>
              <ul v-for="t in body" :key="t">
                <li>{{ t }}</li>
              </ul>
            </v-card-text>
              </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data: () => ({  }),
  props: {
    imgSrc: String,
    title: String,
    body: Array
  }
}
</script>

<style scoped>

</style>
