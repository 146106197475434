<template>
  <div id="case-studies">
    <CaseStudiesPage/>
  </div>
</template>

<script>
  import CaseStudiesPage from '../components/case-studies/CaseStudiesPage.vue'
  export default {
    data: () => ({  }),
    components: {
      CaseStudiesPage
    }
  }
</script>
