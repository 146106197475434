<template>
  <div id="terms-conditions">
    <v-card>
      <v-toolbar flat card dark color="accent">
        <v-toolbar-title><h1>{{ title }}</h1></v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>{{ description }}</p>
        <div v-for="(item, i) in items" :key="i">
          <h2>{{ item.section }}</h2>
          <p>{{ item.body }}</p>
        </div>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import TermsCondStore from '@/stores/TermsCondStore.js'
export default {
  data: () => ({
    title: TermsCondStore.data.title,
    description: TermsCondStore.data.description,
    items: TermsCondStore.data.newItems
  })
}
</script>
