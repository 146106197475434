<template>
  <div id="contact">
    <ContactPage/>
  </div>
</template>

<script>
  import ContactPage from '../components/contact/ContactPage.vue'
  export default {
    data: () => ({  }),
    components: {
      ContactPage
    }
  }
</script>

<style scoped>

</style>
