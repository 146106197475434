<template>
  <div id="contact-page">
    <HeroImage :imgSrc="img" :title="pageTitle" :position="pos"/>
    <v-layout align-center justify-center wrap row>
      <v-flex xs10 offset-xs-1>
        <ContactForm/>
      </v-flex>
    </v-layout>
    
  </div>
</template>

<script>
  import HeroImage from "../layout/HeroImage.vue"
  import ContactForm from "./contact_form.vue";
  export default {
    data: () => ({ 
      pageTitle: 'Contact Us',
      pos: 'bottom 27% center',
      img: 'https://images.unsplash.com/photo-1541946245286-ca6fbe1ad39b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80'
     }),
    props: {
      source: String
    },
    components: {
      ContactForm,
      HeroImage
    }
  }
</script>

<style scoped>
#page-title {
  font-size: 60pt;
  color: white;
}
</style>
