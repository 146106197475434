<template>
  <div id="markets-page">
    <v-container>
      <v-layout fill-height>
        <h1 id="page-title">{{ pageTitle }}</h1>

      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    pageTitle: 'Markets',
    description: ''
  })
}
</script>

<style scoped>
#page-title {
  font-size: 60pt;
}
#description {
  font-size: 16pt;
}
</style>