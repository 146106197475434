<template>
  <div id="details-dialog">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          outline
          v-on="on"
        >
          Details
        </v-btn>
      </template>

      <v-card>
        <DetailsPage :description="description" :pageTitle="pageTitle" :sections="sections"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DetailsPage from '@/components/disciplines/DetailsPage.vue'
export default {
  data: () => ({  }),
  components: {
    DetailsPage
  },
  props: [
    "pageTitle",
    "description",
    "sections"
  ]
}
</script>

<style scoped>

</style>