const CoreCompetencies = {
  data: {
    title: 'Core Competencies',
    body: [
      'Component Sustainment',
      'Design and Development',
      'Manufacturing Support',
      'Reverse Engineering',
      'Supply Chain Qualification',
      'System/Sub-Systems',
      '(Sub)Systems Integration',
      'Test/Test Systems',
      'Validation Testing'
    ]
  }
};

const Services = {
  data: {
    title: 'Services',
    items: [
      {
        title: 'Electrical Engineering',
        description: 'Darceo has a broad range of technical capabilities in the electrical engineering fields. This expertise hs been spread across many designs in many different market segments. The designs range from simple PCBA’s to complex system designs. The skill capabilities are often combined across the team members to complete cross functional product capabilities.',
        body: [
          'Schematic Development',
          'Component Engineering',
          'PCBA Layout',
          'Simulation',
          'Analog',
          'Digital',
          'Power Systems Design',
          'Electronics Hardening',
          'RF Design Mhz to Ghz',
          'Embedded Antenna Design',
          'Embedded Systems',
          'Magnetics',
          'DSP/FPGA/CPLD Micro'
        ],
        path: '/electrical_engineering'
      },
      {
        title: 'Mechanical Engineering',
        description: `Darceo's lean and innovative approach to solving complex problems provides a total solution for highly technical and highly reliable products. With knowledge and access to industry leading toolsets, Darceo can provide the one stop solution to your complex problems.`,
        body: [
          'Structures/Chassis/Hardware Design',
          'Inlets and exhaust',
          'Prototype design and fabrication',
          'Design Validation– Drop',
          'Environmental',
          'Finishes',
          'Stress/Heat',
          'Analysis (FEA)',
          'Hardware Tolerance GD&T',
          'Fastener Technologies',
          'Fluid Mechanics/Pneumatics Systems',
          'Design for Additive Manufacturing (Plastics and Metals)',
          'Plastics',
          'Sheet Metal',
          'Milled',
          '“Printed” Metal',
          'Electromechanical Design & Integration'
        ],
        path: "/mechanical_engineering"
      },
      {
        title: 'Firmware/Software Development',
        description: `Darceo has a combination of engineers that love to cross the rivers of hardware and software integration. Electrical engineers and software engineers combine to integrate CPU architecture, peripherals, sensors, actuators, and firmware to make any embedded system function to the client’s requirements.`,
        body: [
          'Embedded',
          'Bare-Metal Development',
          'Operating Systems: Linux, ThreadX, RTOS',
          'Encrypted & Secure Transactions and Field Updates',
          'Data Acquisition and Instrumentation',
          'Signal Processing (FIR & IIR Filters, FFT Frequency Analysis, etc.)',
          'Network and Web Capable: TCP/IP, LwIP, HTTP/HTTPS, Server/Client, etc.',
          'Internet of Things (IoT)',
          'Database, and Cloud Applications.'
        ],
        path: "/firmware_software_engineering"
      },
      {
        title: 'Industrial Automation',
        description: `Darceo designs, manufactures, and integrates materials handling and automation systems that will increase production, boost production line quality, and maximize return on investment.`,
        body: [],
        path: "/industrial_automation"
      },
      {
        title: 'Process Support & Logistics',
        description: `Darceo is an established provider of professional services and cost-effective automation that answers real business needs. Through our experience in factory automation and control systems, Darceo brings a realistic perspective to the challenges posed by the size, complexity, and diversity towards increased factory throughput.`,
        path: '/process_support_logistics'
      }
    ]
  },
  accessors: {  },
  mutators: {  }
};

const ServicesStore = {
  data: {
    CoreCompetencies,
    Services
  }
};

export default ServicesStore;