<template>
  <div id="corporate-data">
    <v-card>
      <v-toolbar card dark flat color="accent">
        <v-toolbar-title><h1>{{ title }}</h1></v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-layout column>
            <v-layout row wrap align-center>
              <v-flex xs12 sm3><v-img :src="images[1].path" width="200"/></v-flex>
              <v-flex xs12 sm6><v-img :src="images[0].path"/></v-flex>
              <v-flex xs12 sm3><v-img :src="images[2].path" width="200"/></v-flex>
            </v-layout>
            <v-layout column>
              <v-flex xs6><v-img :src="images[3].path" width="150"/></v-flex>
            </v-layout>
            <v-layout row>
            </v-layout>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-text>
        <ul v-for="b in body" :key="b">
          <li>{{ b }}</li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LegalStore from '../LegalStore.js'
export default {
  data: () => ({
    title: LegalStore.data.CorporateData.data.title,
    body: LegalStore.data.CorporateData.data.body,
    items: LegalStore.data.CorporateData.data.items,
    images: LegalStore.data.CorporateData.data.imgs
  })
}
</script>
