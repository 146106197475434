<template>
  <div id="contact-form">
    <v-container fluid>
      <v-layout row wrap align-center justify-center>
        <v-flex xs12>
          <v-card>
            <v-toolbar color="primary" flat>
              <v-toolbar-title id="contact-us-title">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-layout column>

                    <v-flex xs12 md6>
                      <v-layout column>
                        <v-flex xs12>
                          <h1>Darceo Inc.</h1>
                        </v-flex>
                        <v-flex xs12>
                          <v-icon small>mdi mdi-phone</v-icon>
                          <i> (605)-720-8028</i>
                        </v-flex>
                        <v-flex xs12>
                          <v-icon small>mdi mdi-email</v-icon>
                          <i> assistance@darceo.com</i>
                        </v-flex>
                        
                        <v-flex xs12>
                          <p>
                            1675 Samco Road <br/>
                            Rapid City, SD 57702<br/>
                            United States of America
                          </p>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-layout column>
                        <v-flex
                          xs12
                          md4
                        >
                          <v-text-field
                            v-model="firstname"
                            :rules="nameRules"
                            label="Full name"
                            outline
                          ></v-text-field>
                        </v-flex>

                        <v-flex
                          xs12
                          md4
                        >
                          <v-text-field
                            v-model="companyname"
                            :rules="nameRules"
                            :counter="10"
                            label="Company name"
                            required
                            outline
                          ></v-text-field>
                        </v-flex>

                        <v-flex
                          xs12
                          md4
                        >
                          <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="E-mail"
                            required
                            outline
                          ></v-text-field>
                        </v-flex>
                        <v-flex
                          xs12
                          md4
                        >
                          <v-text-field
                            v-model="message"
                            label="Message"
                            :counter="140"
                            clearable
                            outline
                            height="200"
                          ></v-text-field>
                        </v-flex>
                        <v-layout column wrap>
                          <v-flex xs4>
                            <v-btn color="success">Submit</v-btn>
                            <v-btn outline color="secondary">Clear</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    title: 'Leave us a message',
    valid: false,
    firstname: '',
    companyname: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 10 || 'Name must be less than 20 characters'
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    message: ''
  })
}
</script>

<style scoped>
#contact-us-title {
  color: white;
  font-size: 20pt;
}
</style>
