const CaseStudiesStore = {
  data: {
    sections: [
      {
        title: 'Medical',
        items: [
          {
            title: 'Inner Eye Vision System',
            body: [
              'EKG correlation to blood movement in eye for heart diagnostics',
              'Turn-key system integration and platform design',
              'Mechanical system design',
              'Optics system and lighting design',
              'Human factors specification and use'
            ],
            tag: 'med',
            img: require("@/assets/case-studies/eye_pic.png")
          },
          {
            title: 'Opthalmology Surgery Tool',
            body: [
              'Turn-key product to market',
              'Mechanical enclosure design',
              'Electronics design/board layout',
              'Turn-key manufacturing',
              'Limited cost program (13K)'
            ],
            tag: 'med',
            img: require("@/assets/case-studies/Mastel_Light2.jpg")
          }
        ]
      },
      {
        title: 'Military & Aerospace',
        items: [
          {
            title: 'Advanced Process Development',
            body: [
              'Component Analysis and Repair process development',
              'Equipment and process development for advanced weapon systems components​​'
            ],
            tag: 'mil',
            img: require("@/assets/case-studies/3DRail.jpg")         
          },
          {
            title: 'Military New Technology Integration',
            body: [
              'Air Force Research and Development Program',
              'Advanced Manufacturing Process Implementation - Cold Spray',
              'FEA/Stress Analysis,',
              'Elasticity, Crack Propagation, Thermal analysis, Wear analysis, Environmental analysis',
              'Product Requirements Definition',
              'Test Validation - Flight Analysis',
              'AS9100 Regulatory Control'
            ],
            tag: 'mil',
            img: require("@/assets/case-studies/boat.jpg")
          },
          {
            title: 'Military Equipment Integration',
            body: [
              'Additive manufacturing',
              '3-axis motion systems',
              'Electrical and Mechanical Design',
              'Software Development - LabVIEW/Softmotion',
              '1,000 lb Load',
              '50 MM/sec Linear Speed',
              '200 lb Load @ 700 RPM'
            ],
            tag: 'mil',
            img: require("@/assets/case-studies/jets.png")
          }
        ]
      },
      {
        title: 'Industrial',
        items: [
          {
            title: 'MRI Component System Test',
            body: [
              'Multi-select head switch - 196 RF paths',
              'MRI system switching',
              '333 Mhz to 2.3Ghz',
              'Multiple amplifier stages 9 assemblies',
              'Board level test - gallium arsenide FETS',
              'Software Development - Labview/Test stand',
              'Power, S-parameter, network analyzer'
            ],
            tag: 'ind',
            img: require("@/assets/case-studies/mri_machine_test.png")
          },
          {
            title: 'Plasma Electrolytic Deposition',
            body: [
              'Full turn-key production system development',
              'Produce surfaces 3-5x harder and more wear-resistant than traditional hard anodized coatings',
              'Mixed Chemistry Technology (Graphite for wear; Colorants)',
              'Complete design: Conception to production',
              'Fast high DC (1KV) voltage/current switching -200 to 600 VDC up to +/-72 Amps at 3Khz',
              'Software development - Labview/Embedded programming',
              'PCB Design and layout',
              'Fully programmable recipes'
            ],
            tag: 'ind',
            img: require("@/assets/case-studies/plasma_1.png")
          },
          {
            title: 'Metals Systems Design',
            body: [
              'Full system redesign for cost reduction',
              '37% Materials reduction',
              '60% Assembly Labor reduction',
              'Improved Reliability'
            ],
            tag: 'ind',
            img: require("@/assets/case-studies/led_board.jpg")
          }
        ]
      },
      {
        title: 'Internet of Things (IoT)',
        items: [
          {
            title: 'Home Controls Product Design',
            body: [
              'Turn-key Concept to Market',
              'Electrical Design',
              'Full Embedded System including Cybersecurity',
              'Injection Molding Design',
              'Electrical-Mechanical Integration',
              'Full Regulatory'
            ],
            tag: 'iot',
            img: require("@/assets/case-studies/honeywell_model.jpg")
          },
          {
            title: 'Power Storage System',
            body: [
              'Turn-key solution for smart home power management',
              'Full system design and integration',
              'Firmware control design with power system controls',
              'Multiple power inputs - solar, grid, wind',
              'Cellular Communication to secure server',
              'Full user interface with customizable dashboard for grid power monitoring and analytic reporting'
            ],
            tag: 'iot',
            img: require("@/assets/case-studies/battery2.png")
          },
          {
            title: 'Power Grid Monitoring Technology',
            body: [
              'Turn-key solution for power grid monitoring of utility poles',
              'Full system design and integration into server',
              'Firmware design and integration into device',
              'Receive immediate notification of problematic movement or electrical faults',
              'Cellular communication to secure server',
              'Full user interface with customizable dashboard for grid monitoring and analytic reporting'
            ],
            tag: 'iot',
            img: require("@/assets/case-studies/smrtgrid_beacon.jpg")
          }
        ]
      }
    ]
  }
};

export default CaseStudiesStore;