<template>
  <div id="legal">
    <LegalPage/>
  </div>
</template>

<script>
  import LegalPage from '../components/legal/LegalPage.vue'
  export default {
    data: () => ({  }),
    components: {
      LegalPage
    }
  }
</script>
