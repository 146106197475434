const EthicsStore = {
  title: 'Ethics Statement',
  description: `Darceo , Inc. conducts its business in compliance with the laws of the United States and other jurisdictions in which it operates and according to the highest ethical standards. Integrity is the cornerstone of the way Darceo  does business. Ethical conduct is not only the right thing to do, it is good business.
  Employees and consultants of Darceo  shall adhere to this policy. The following summarizes Darceo ’s high-level policies on key ethical business issues`,
  items: [
    { name: `Corporate Citizenship`, text: ` – Darceo  and its employees will comply with all applicable laws and regulations.` },
    { name: `Work Place Conduct`, text: ` – Darceo  employees will act in a fair, honest and lawful manner in all business relationships with customers, suppliers, employees, applicants, and community members.` },
    { name: `Quality`, text: ` – No employee shall knowingly misrepresent the condition or status of Darceo 's research, products or services. Constant validation through testing and accurate documentation ensures that products meet or exceed performance and quality specifications. Darceo  is committed to complying with external quality systems such as ISO 9001-2000.` },
    { name: `Procurement Integrity`, text: ` – No employee, either directly or through an intermediary, may knowingly obtain confidential information from or concerning a customer, supplier or competitor, unless the information is provided with the consent of all concerned parties.` },
    { name: `Conflicts of Interest`, text: ` – All employees will conduct themselves with the highest standards of integrity, honesty and fair dealing to preclude conflicts between the interests of Darceo  and the personal interests of employees.` },
    { name: `Providing Cost or Pricing Data to the US Government`, text: ` – Cost or pricing data shall be current, accurate and complete in accordance with the Truth in Negotiations Act. Employees will not make any material or non-approved substitutions from specifications and will ensure that all products meet or exceed contractual specifications.` },
    { name: `Charges to US Government`, text: ` – Only costs that are allowable and allocable to a contract under law and Federal Acquisition Regulations may be charged to the US Government.` },
    { name: `Financial Accounting, Records and Communications`, text: ` – Documents will be prepared as completely, honestly, and accurately as possible. Disbursements of funds and receipts will be properly and promptly recorded. Any inaccurate description of labor costs is strictly prohibited.` },
    { name: `Business Courtesies and Kickbacks`, text: `Business Courtesies and Kickbacks – Darceo  will conduct business on the basis of quality, performance and price without giving or accepting anything of value that could, in fact or in appearance, influence the outcome of a transaction or negotiation.` },
    { name: `International Business Practices`, text: ` – Darceo  employees will comply with the provisions of the Foreign Corrupt Practices Act and all export control laws and regulations.` },
    { name: `Employing and Recruiting US Government Personnel`, text: ` – Darceo  complies with laws and regulations concerning employment and recruiting of current or former US Government personnel.` },
    { name: `Classified and Proprietary Information`, text: ` – All employees will deal with sensitive information (including US Government classified information, Darceo  proprietary information, and proprietary information of others) in the proper manner, both as a matter of national security and to assure compliance with applicable laws, regulations and contractual requirements. Unauthorized access, dissemination, acceptance or handling of such material is prohibited and may constitute a violation of law.` },
  ],
  sections: [
    { bold: `Compliance Program`, text: `Darceo  maintains a program to enforce the letter and spirit of this policy. Violations of Darceo 's standards will be met with disciplinary actions up to and including discharge from the company and/or legal action.` },
    { bold: ``, text: `Darceo employees and anyone conducting business with Darceo  are encouraged to report any violations of Darceo 's Code of Business Conduct or other related policies to Darceo 's Ombudsman. Also, Darceo  employees may contact the Defense Hotline for Fraud, Waste and Abuse Reporting. Darceo  will not retaliate against employees who have conscientiously reported potential illegal or unethical acts.` },
    { bold: `Responsibilities`, text: `The Business Standards Compliance Committee, appointed by the President and Chief Executive Officer, reviews the activities of Darceo 's standards of business conduct and compliance program and approves actions concerning ethics issues. The Senior Vice President and Chief Operations Officer has overall responsibility for compliance with this policy.` },
    { bold: ``, text: `The head of each business division is responsible for compliance with this policy by all employees within that division.` },
    { bold: ``, text: `Questions or concerns regarding Darceo's Standards of Business Conduct or its application to specific situations may be addressed to the members of the Business Standards Compliance Committee, Darceo 's Ombudsman, Darceo 's Internal Control Department, or any of the signatory executives.`}
  ]
}

export default EthicsStore;