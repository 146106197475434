<template>
  <div id="case-studies-page">
    <HeroImage :imgSrc="img" :title="pageTitle" :position="pos"/>
    <v-container fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12 sm10 offset-sm1
          v-for="s in caseStudiesStore.sections"
          :key="s.title"
        >
        <v-card color="supporting">
          <v-toolbar flat dark> <v-toolbar-title v-text="s.title"/> </v-toolbar>          
          <v-container fluid grid-list-sm fill-height>
            <v-layout row wrap>
              <v-flex xs12
                v-for="i in s.items"
                :key="i.title"
              >
                <v-card min-height="300">

                  <!-- <v-layout column>
                      <v-flex xs12 sm3>
                    <div v-if="i.img">
                        <v-img :src="i.img" height="300" contain></v-img>
                    </div>
                    <v-card-title primary-title>
                      <h3>{{ i.title }}</h3>
                    </v-card-title>
                      </v-flex>
                      <v-flex xs12 sm9>
                      <v-card-text>
                        <ul v-for="t in i.body" :key="t">
                          <li>{{ t }}</li>
                        </ul>
                      </v-card-text>

                      </v-flex>
                  </v-layout> -->
                  
                  <CaseStudy :imgSrc="i.img" :title="i.title" :body="i.body"/>
                  <!-- <CaseStudy :imgSrc="i.img" :cardTitle="i.title" :body="i.body"/> -->
                  </v-card> 


              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    

  </div>
</template>

<script>
import CaseStudiesStore from './CaseStudiesStore.js'
import HeroImage from '../layout/HeroImage.vue'
import CaseStudy from './CaseStudy.vue'

export default {
  data: () => ({
    pageTitle: 'Case Studies',
    pageSubtitle: 'Market-specific groups/categories',
    caseStudiesStore: CaseStudiesStore.data,
    markets: [
      {
        title: 'Medical',
        tag: 'med',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel.'
      },
      {
        title: 'Military & Aerospace',
        tag: 'mil',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel.'
      },
      {
        title: 'Industrial',
        tag: 'ind',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel.'
      },
      {
        title: 'Internet of Things (IoT)',
        tag: 'iot',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh praesent tristique magna sit amet purus gravida quis. Eget sit amet tellus cras. Purus viverra accumsan in nisl nisi scelerisque eu. Quisque egestas diam in arcu. A pellentesque sit amet porttitor eget dolor morbi non. Parturient montes nascetur ridiculus mus mauris vitae. Laoreet id donec ultrices tincidunt arcu non sodales neque. Sapien pellentesque habitant morbi tristique senectus. Varius duis at consectetur lorem donec massa sapien faucibus et. Bibendum est ultricies integer quis auctor. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Adipiscing at in tellus integer feugiat scelerisque. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Purus non enim praesent elementum facilisis leo vel.'
      }
    ],
    img: 'https://images.unsplash.com/photo-1487875961445-47a00398c267?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
    pos: 'bottom 20% center'
  }),
  components:{
    HeroImage,
    CaseStudy
  }
  
}
</script>

<style scoped>
  #page-title {
    font-size: 60pt;
    color:white;
  }
  #page-subtitle {
    font-size: 18pt;
    color:white;
  }
</style>