const TermsConditionsStore = {
  data: {
    title: 'Terms & Conditions',
    description: `All sales of engineering services, design services, detail drawing services, consulting and training services, inspection and analysis
    services, and testing services by Darceo (collectively referred to herein as “Engineering Services”) are subject to the following terms and
    conditions. All proposals, quotations or acknowledgments issued by Darceo are an offer to sell Engineering Services pursuant to these
    terms and conditions. Darceo objects to any additional or different terms contained in any documentation submitted by Customer. No
    waiver or modification of these terms and conditions shall be binding on Darceo unless authorized in writing by Darceo.`,
    items: [
      {
        section: 'SCOPE',
        body: `The scope of work for the Engineering Services to be provided to Customer is specifically set forth in the proposal, quote, or
        acknowledgment submitted to Customer by Darceo. If Customer requests a change in the scope of the Engineering Services to be
        provided, Darceo reserves the right to revise delivery schedules and make an equitable adjustment to the price. Customer acknowledges
        and agrees that Darceo is providing the Engineering Services only and is not providing or participating in the provision of any product(s).
        Darceo will not be obligated to provide any services which are (a) outside of the scope defined in the applicable documentation; (b) outside
        its area of expertise; or (c) in violation of any applicable laws, codes or regulations.`
      },
      {
        section: 'CUSTOMER OBLIGATIONS',
        body: `Customer shall make available in a timely manner at no charge to Darceo all drawings, technical data,
        measurements, or other information and resources reasonably required by Darceo for the performance of the Engineering Services.
        Customer will be responsible for, and assumes the risk of any problems resulting from, the content, accuracy, completeness and
        consistency of all such data, materials and information supplied by Customer.`
      },
      {
        section: 'PRICES/TAXES',
        body: `Unless otherwise stated or agreed, Darceo's prices do not include sales, use, or similar taxes. Taxes, fees, costs of
        permits or licenses, etc. related to the Engineering Services and levied against Darceo will be considered reimbursable expenses to be
        paid by Customer.`
      },
      {
        section: 'TIME AND MATERIALS',
        body: `Charges for Engineering Services that are conducted on a Time and Materials basis will be according to the
        attached Rate Schedule. Overtime costs will be added for Customer-requested overtime of non-professional (below engineering level)
        personnel. Overtime is defined as work in excess of 8 hours per day or 40 hours per week. Time spent preparing for or participating in
        legal proceedings will be billed at 150% of the rates given in the Rate Schedule.
        
        The labor hours required to perform the Engineering Services will be recorded on a time sheet by the individual performing the services.
        Labor hours will be recorded to the nearest quarter hour. Actual hours worked will be recorded for Engineering Services performed in
        Darceo’s or Customer’s offices. On international projects, the labor hours spent in obtaining visas, medical certifications, inoculations, etc.
        will be also be recorded. Travel time will be recorded portal-to-portal, home office`
      },
      {
        section: 'PAYMENT TERMS',
        body: `Customer shall be responsible for timely payment of invoices, regardless of any agreement that Customer may
        make regarding cost sharing with other parties. Invoices will normally be submitted every four weeks, in U.S. Dollar amounts, for
        charges accrued during the previous four weeks. Standard payment terms are net 30 days for creditworthy customers. Payment must
        be made in U.S. Dollars by check or order drawn on a U.S. bank; or, subject to Darceo approval, on a foreign bank having a
        corresponding agreement with a U.S. Bank. Any bank charges made to process an instrument will be considered a reimbursable
        expense. Payments not received within 30 days will incur a 1% finance charge per month until the payment is made. Darceo reserves the
        right to stop work on projects where payment is past due.`
      },
      {
        section: 'LIMITED WARRANTY',
        body: `Darceo will provide the Engineering Services in accordance with generally accepted professional engineering
        practices using reasonable care and skill consistent with that ordinarily exercised by members of the profession under similar
        conditions. However, due to the nature of the Engineering Services being provided, Darceo cannot fully guarantee the success of
        Customer’s project. As such, except as set forth in this Section, Darceo makes no warranties or guarantees, whether express, implied, or
        statutory, regarding or relating to the Engineering Services furnished under this Agreement. Darceo specifically disclaims all implied
        warranties of merchantability and fitness for a particular purpose with respect to the Engineering Services.`
      },
      {
        section: 'REMEDY FOR BREACH OF THE LIMITED WARRANTY',
        body: `The parties acknowledge and agree that the Engineering Services are being
        provided by Darceo with the expectation that Darceo is not assuming any financial or operational risks of the Customer. In the event Darceo
        commits an error with respect to, or incorrectly performs the Engineering Services, Darceo shall use commercially reasonable efforts to
        correct such error, or re-perform such Engineering Services at no cost to Customer. Customer acknowledges that its sole and exclusive
        remedy, and Darceo’s sole and exclusive liability, for any defect or error in the Engineering Services shall be correction, re-performance or
        substitution of such services by Darceo`
      },
      {
        section: 'LIMITATION OF LIABILITY',
        body: `Darceo’s liability for a claim of any kind arising out of the Engineering Services provided pursuant to this
        Agreement shall in no case exceed the price paid by Customer. In no event shall Darceo be liable for any special, indirect, incidental or
        consequential damages, including loss of profits or business interruption or loss of use of equipment, however caused, arising from the
        Engineering Services provided pursuant to this Agreement.`
      },
      {
        section: 'DELIVERY/FORCE MAJUERE',
        body: `Darceo shall have no liability for delays or any other breach of its obligations resulting from an Act of God,
        war, riot, explosion, accident, act of government, work stoppage, default of subcontractor or supplier of materials, or any other cause
        beyond the reasonable control of Darceo.`
      },
      {
        section: 'CANCELLATION, SUSPENSION OR DELAY',
        body: ` Customer may cancel an order for Engineering Services upon written notice to Darceo and
        payment of an agreed upon cancellation charge, which shall include all costs incurred by Darceo prior to the cancellation plus a
        reasonable profit. A purchase order may be suspended or delayed by Customer with Darceo’s prior written consent. If Darceo agrees to a 
        suspension or delay, Customer shall reimburse Darceo for all costs incurred up to the date of such suspension or delay, plus a reasonable
        profit. All other costs related to and risks incidental to resumption of the Services shall be borne by Customer.`
      },
      {
        section: 'DOCUMENTATION',
        body: ` Except as otherwise specifically set forth in the scope of work provided as part of the proposal or quotation, all
        documents, including drawings, specifications, computer files, electronic media, data, engineering calculations, notes, and other
        documents and instruments prepared or furnished by Darceo (collectively the “Documentation”) are the property of Darceo and shall not be
        considered works for hire. Darceo shall retain all common law, statutory and other reserved rights, including copyright, applicable to the
        Documentation. The Documentation is not intended or represented to be suitable for use on any other project. Any reuse of the
        Documentation without written verification or adaptation by Darceo for the specific purpose intended is prohibited and will be at Customer’s
        sole risk and without liability or legal exposure to Darceo. Customer agrees to defend, indemnify and hold Darceo harmless against all claims,
        damages, losses, and expenses (including reasonable attorneys’ fees) arising from or in any way connected with the unauthorized use
        or modification of the Documentation by Customer or any person or entity that acquires or obtains the Documentation from or through
        Customer without the written authorization of Darceo.`
      },
      {
        section: 'HARDWARE',
        body: `Except as otherwise specifically set forth in the scope of work provided as part of the proposal or quotation, any tools,
        hydraulic equipment, instrumentation, structural steel, or other hardware (collectively the “Hardware”) that is purchased for the purpose
        of conducting the Engineering Services are the property of Darceo. Customer-owned hardware or test samples shall be removed from
        Darceo premises within 90 days of the completion of the Engineering Services at the sole cost and expense of the Customer. Hardware or
        test samples that remain after 90 days shall incur storage fees as given in the Rate Schedule.`
      },
      {
        section: 'INTELLECTUAL PROPERTY RIGHTS',
        body: `Each party shall retain ownership of all intellectual property it had prior to commencement of
        the Engineering Services. Except as otherwise set forth on the scope of work, Customer shall own the rights to all discoveries,
        improvements, and inventions conceived of or made by Darceo that are a direct result of the Engineering Services performed for
        Customer. At Customer’s request and expense, Darceo shall execute all papers and deliver to Customer all data and other information,
        and perform other acts as Customer may require, to assign and transfer all Darceo’s right, title and interest therein to Customer and to
        enable Customer to apply for, obtain, and enforce patent protection thereon. Time and expenses incurred by Darceo in connection
        therewith shall be paid by Customer.`
      },
      {
        section: 'INDEMNITY',
        body: `Customer will defend, indemnify, and hold Darceo harmless from all claims, damages, losses, and expenses (including
        reasonable attorneys’ fees) arising out of the provision of the Engineering Services by Darceo under this Agreement, including claims
        related to Darceo’s use of Customer supplied drawings, measurements, data, or any other information provided by Customer that is used
        in performing the Engineering Services. However, in no event shall Customer be liable under this provision for claims arising out of the
        sole negligence or willful misconduct of Darceo.`
      },
      {
        section: 'COMPLIANCE WITH LAWS ',
        body: `The parties agree to comply with all applicable federal, state, or local laws in connection with the
        Engineering Services being provided pursuant to this Agreement.`
      },
      {
        section: 'GOVERNING LAW',
        body: `The relationship between Customer and Darceo shall be governed by the laws of the State of Texas, United States of
        America, and the parties agree that the United Nations Convention on Contracts for the International Sale of Goods shall not apply.`
      },
      {
        section: 'DISPUTE REOLUTION',
        body: `All claims or disputes of any kind arising out of the relationship between Customer and Darceo shall be
        submitted to mediation prior to filing suit. The language to be used in mediation shall be English. Any action filed between the parties
        shall be filed in the state or federal courts in and for Comal County, Texas. The prevailing party shall be entitled to recover its costs,
        including reasonable attorneys’ fees.`
      },
      {
        section: 'EXPORT CONTROL',
        body: `In the event that U.S. or local law requires export authorization for the export or re-export of any technology
        included or associated with the Engineering Services being provided, then no delivery can be made until such export authorization is
        obtained, regardless of any otherwise promised delivery date. In the event that any required export authorization is denied, Darceo will be
        relieved of any further obligation relative to the sale and/or delivery of the Services subject to such denial without liability of any kind
        relative to Customer or any other party. Darceo will not comply with boycott related requests except to the extent permitted by U.S. law
        and then only at Darceo’s sole discretion.`
      },
      {
        section: 'ASSIGNMENT',
        body: `Customer may not assign the Agreement between Darceo and Customer without the prior written consent of Darceo.`
      },
      {
        section: 'THIRD-PARTY BENEFICIARIES',
        body: `Nothing contained in this Agreement shall create a contractual relationship with or a cause of action
        in favor of a third party against Darceo. Darceo’s Engineering Services are being performed solely for Customer’s benefit, and no party or
        entity shall have any claim against Darceo because of this Agreement or the performance or nonperformance of the Engineering Services.`
      },
      {
        section: 'INDEPENDENT CONTRACTORS',
        body: `Each party will be and act as an independent contractor and not as an agent or partner of, or joint
        venture with, the other party for any purpose related to this Agreement or the transactions contemplated by this Agreement, and neither
        party by virtue of this Agreement will have any right, power, or authority to act or create any obligation, expressed or implied, on behalf
        of the other party.`
      },
      {
        section: 'ENTIRE AGREEMENT',
        body: ` This Agreement represents the entire and integrated Agreement between Customer and Darceo and supersedes
        all prior negotiations, representations or agreements either written or oral. This Agreement may be amended only by written instrument
        signed by both Customer and Darceo.`
      }
    ],
    newItems: [
      { section: `Scope`, body: `Seller means the Darceo, Inc. entity identified in the Seller Documents (defined below), and Buyer means the industrial company purchasing Goods, Services and/or Software from Seller. Any Seller proposal, acknowledgment or invoice and all documents incorporated by specific reference herein or therein by Seller (“Seller Documents”), and these terms and conditions of sale (“Terms”), constitute the complete terms and conditions governing the sale of Goods, Services and/or Software (“Agreement”). ` },
      { section: `Precedence`, body: `ANY AND ALL ADDITIONAL OR DIFFERENT TERMS IN THE BUYER’S REQUEST FOR PROPOSAL, PURCHASE ORDER, BUSINESS FORMS, WEBSITE OR BY ANY OTHER DOCUMENTATION ISSUED BY BUYER ARE HEREBY DEEMED TO BE MATERIAL ALTERATIONS AND NOTICE OF OBJECTION TO AND REJECTION OF SUCH TERMS IS HEREBY GIVEN. 

      Commencement of any work by Seller or Buyer's acceptance of delivery of the Goods, Services and/or Software will manifest Buyer's assent to this Agreement. Additional or different terms applicable to a particular sale may be specified in the body of a Seller Document or agreed to in a written contract signed by both parties. In the event of a conflict, the following order of precedence will apply: 
      a. written contract with terms and conditions signed by both parties; and
      b. Seller Documents; 
      ` },
      { section: `Definitions`, body: `Unless the context otherwise requires: 
      a. "Goods" as used herein means Goods sold under this Agreement, as identified in the Seller Documents; 
      b. “End-User” is the person or entity who uses the Goods or possesses them without any intention of resale. The “initial” End-User is the first End-User; 
      c. "Services" as used herein means all labor, supervisory, technical and engineering, installation, commissioning, programming, support, repair, training, consulting or other services provided by Seller under the Agreement; and 
      d. “Software” as used herein means all software, plus software documentation, if any, licensed to Buyer by Seller under this Agreement. 
      ` },
      { section: `Prices`, body: `a. Proposals for Goods, Services and/or Software expire thirty (30) days from the date thereof. 
      b. Prices for Services are based on normal business hours. Seller reserves the right to charge Buyer overtime rates for Services rendered outside normal business hours, holiday pay for working on holidays and travel time. 
      c. Seller’s price does not include any federal, state or local property, license, privilege, sales, value-added, use, excise, gross receipts, or other like taxes which may now or hereafter be applicable. Buyer agrees to pay or reimburse any such taxes which Seller or its suppliers are required to pay or collect. If Buyer is exempt from the payment of any tax or holds a direct payment permit at the time of the placement of order, Buyer shall provide Seller a copy, acceptable to the relevant governmental authorities of any such certificate or permit. 
      d.	Seller’s price excludes customs, duties and other similar fees which may not or hereafter be applicable. 
      
      Buyer agrees to pay or reimburse any such customs, duties and other fees which Seller or its suppliers are required to pay or collect. Seller neither represents nor guarantees that any of the Goods qualify as originating under the North American Free Trade Agreement, Buy America, General System of Preferences or other relevant, existing or future trade agreements or tariff preference programs. Any duties, fees, taxes, other charges or exactions on the Goods payable to any government or other entity are the sole responsibility of the Buyer. Seller’s price includes standard packaging for shipment by truck, unless expressly stated otherwise in the Seller Documents. Any change after the proposal date in such rates, or additional packaging required by Buyer or required to transport the Goods or Software via another mode of transportation, shall be paid to Seller in addition to the quoted price. 
      ` },
      { section: `Payment`, body: `a.	All payments are due upon terms in agreement or upon receipt of invoice. 
      b. Seller has no obligation to ship any Goods to Buyer or to complete future milestones until Buyer is current on all payments due. 
      c. If in the judgment of Seller, the financial condition of Buyer at any time prior to shipment does not justify the terms of payment originally specified, Seller may require payment in advance, payment security satisfactory to Seller, or may terminate the Agreement for default, whereupon Seller shall be entitled to receive the charges set forth in Section 18 below. If shipment is delayed by Buyer, all payments shall become immediately due and payable on the date Seller is prepared to ship. Delays in shipment or nonconformities in any installments delivered shall not relieve Buyer of its obligation to accept and pay for remaining installments. 
      d. Buyer shall pay, in addition to the overdue payment, a late charge equal to the lesser of 1 1/2% per month or any part thereof or the highest applicable rate allowed by law on all such overdue amounts plus Seller's attorneys' fees and court costs incurred in connection with collection. 
      ` },
      { section: `Changes`, body: `a. Every change order shall reflect modifications to the Agreement, the delivery schedule and the price. A change order is not binding on either party mutually agreed to in writing. Seller has no obligation to perform any changes until the change order is mutually agreed in writing. 
      b. Seller may make such changes in the Goods, Services and/or Software as it deems necessary, in its sole discretion, to conform the Goods, Services and/or Software to the applicable specifications. If Buyer objects to any such changes, Seller shall be relieved of its obligation to conform to the applicable specifications to the extent that conformance may be affected by such objection. 
      ` },
      { section: `Shipment & Delivery`, body: `a.	Goods are shipped on a domestic basis: EXW, point of shipment and on an international basis: EXW: Seller’s facility (Incoterms 2010) unless otherwise stated in the Sellers Documents. 
      b. Buyer shall be responsible for any and all demurrage, detention, customs broker and freight forwarder fees, warehouse and terminal charges, insurance, inspection, storage, special notifications, and special equipment/handling charges shall be at the Buyer’s additional expense unless otherwise agreed in writing by Seller. 
      c. Shipping and delivery dates are estimates only and are contingent upon Buyer's timely approvals and delivery by Buyer of any documentation required for Seller's performance hereunder. Seller shall not be liable for any penalties or damages of any kind if anticipated shipment dates are not met. Delivery times shall be automatically extended as needed to resolve any technical matters between the parties with respect to the delivery, installation or use of the Goods and/or Software. 
      d. If the scheduled delivery of Goods, Services and/or Software is delayed by Buyer, Seller may store in its facility or move the Goods and/or Software to storage, at Buyer’s sole cost, expense and risk, whereupon the Goods and/or Software are deemed to be delivered and accepted by Buyer and all payments shall be accelerated and come immediately due and payable on the date Seller is prepared to make delivery – notwithstanding any terms to the contrary stated in Sellers Documents.
      e. Seller may make partial deliveries. 
      ` },
      { section: `Title & Risk of Loss`, body: `Title and risk of loss for the Goods shall transfer to Buyer upon delivery of the Goods to the first carrier for shipment. Seller retains a purchase money security interest on and in such Goods until Seller receives payment in full, and Buyer will cooperate with Seller to perfect any such interest as deemed reasonably necessary by Seller. ` },
      { section: `Buyer's Obligations`, body: `a. Buyer must provide required inputs and approvals on a timely basis. Buyer must complete site preparation work prior to shipment of the Goods and/or Software and performance of the on-site acceptance test, if applicable. Seller will not be liable for any delays caused by Buyer’s failure to complete its site preparation obligations. 
      b. With respect to all of the Buyer’s tools, equipment, material, or other property such as parts and test samples that are used in the design, assembly, manufacture, or testing of the Goods and/or Software (collectively “Buyer’s Property”) provided to Seller, Buyer agrees that Seller shall have the right to use Buyer’s Property without payment of consideration, and if Buyer requires return or scrap of Buyer’s Property, it will be at Buyer’s direction and expense. Seller is not liable for any damages to Buyer’s Property or any other parts and test samples supplied by Buyer during the manufacturing / testing process. Buyer must timely provide a sufficient number of test samples that meet the agreed upon specification in connection with the Goods, Services and/or Software being purchased by Buyer. If there are too few test samples or the test samples do not meet the agreed upon specifications, Seller may, in its sole discretion and at Buyer’s sole cost and expense: 
      1. require Buyer to submit a sufficient number of test samples or to provide test samples that meet the specifications; 
      2. create additional test samples, or rework / modify existing test samples to meet the specifications; 
      3. be released from every obligation to test the Goods and/or Software, accelerate payment in full for the Goods, Services and/or Software then owing to Seller, and ship the Goods and/or Software as-is upon receipt of payment in full; or 4) terminate the Agreement for cause, whereupon Seller shall be entitled to receive cancellation charges set forth in Section 18 below. 
      c) Notwithstanding any other provision contained herein or any other obligation of Buyer hereunder, Buyer, upon acceptance of Goods, Services and/or Software that are the subject of this Agreement, warrants that Buyer, its employees, agents, customers, representatives, successors and assigns are industrial users of such Goods, Services and/or Software and possess the knowledge and expertise to use the same in accordance with 
      i. accepted industry standards, including, but not limited to, those promulgated by the American National Standards Institute, the American Welding Society, the Robotic Industry Association standards and the Laser Institute of America standards, 
      ii. all applicable laws, including, but not limited to, OSHA’s Hazard Communication Standard; 
      iii. prudent safety practices, and 
      iv. operating manuals, safety data sheets, warning labels and other written instructions provided by Seller, if any. In addition to other obligations stated herein, Buyer assumes all risk and liability for loss or damage resulting from the handling, use, or application of the Goods, Services and/or Software. Buyer agrees that it has an independent duty to familiarize itself with and keep informed of any safety and/or health hazards to persons and/or property involved in handling and using such Goods, Services and/or Software. Buyer shall advise its employees, customers, agents, distributors, consultants, independent contractors and others who may foreseeably handle or use such Goods, Services and/or Software of any hazards. 
      d) Buyer agrees to indemnify, defend and hold harmless Seller, its subsidiaries and affiliates and their respective directors, officers, shareholders, customers, employees, agents, successors and assigns of each from and against any and all liabilities, losses, costs or damages, including reasonable attorneys’ fees, resulting from claims (unless finally determined to be the result of the gross negligence or willful misconduct of Seller) that arise from 
      i. use or handling of the Goods, Services and/or Software by Buyer or any third party, whether or not the Goods, Services and/or Software are combined with any other materials, substances or equipment or is used in any manufacturing process; 
      ii. failure by Buyer to disseminate safety and health information as required above; and 
      iii. failure of Buyer to comply with Section 26.
      ` },
      { section: `Inspection, Testing and Acceptance`, body: `a. If Seller is not providing a factory acceptance test, Buyer will be deemed to have accepted the Goods and/or Software upon delivery. 
      b. If the Agreement provides for factory acceptance testing, Seller shall notify Buyer when Seller will conduct factory testing for compliance to Seller’s specifications prior to shipment. Unless Buyer states specific objections in writing within two (2) days after completion of factory acceptance testing, completion of the factory acceptance test constitutes Buyer's acceptance of the Goods and/or Software and Buyer’s authorization for shipment. If the Agreement provides for factory acceptance testing and Buyer instructs Seller to ship the Goods and/or Software without completing the factory testing, Buyer has 
      i. waived the factory acceptance test, 
      ii. accepted the Goods, Services and/or Software as-is through such waiver; 
      iii. accelerate payment in full for the Goods, Services and/or Software then owing to Seller, and iv) ship the Goods and/or Software as-is upon receipt of payment in full. 
      c. If the Agreement provides for site acceptance testing, testing will be performed by Seller personnel at Buyer’s site to verify compliance to Seller’s specifications. Completion of site acceptance testing constitutes final acceptance of the Goods, Services and/or Software. If, through no fault of Seller, site acceptance testing is not completed within thirty (30) days after arrival of the Goods at the site, the site acceptance test shall be deemed completed. Upon completion or deemed completion of on-site acceptance testing, any final payment is immediately due and owing to Seller. 
      ` },
      { section: `Warranties and Remedies`, body: `a.	Warranty.
      For a period of 12 months from the date of Acceptance, Seller warrants the Products will:
      i.	Be free from defects in workmanship; equipment warranty will be passed through as provided by the OEM equipment manufacturer
      ii.	Conform to the Acceptance Criteria.
      
      If for any reason not within Seller's control, acceptance is delayed by 60 days or more after the original planned ship date, the above warranties shall apply only if Seller is given the opportunity to inspect, service, and condition the Product to insure it is in proper operating condition. Inspection and servicing required due to the delay are at additional cost and will be billed to Buyer at Seller's then current service rate. In any event, provided that Seller is not responsible for such delay, warranty shall start no later than 90 days after the Product's original planned ship date.
      
      If Buyer reports what is believed to be a defect covered under this warranty, Seller will promptly respond and initiate appropriate corrective action including, if necessary, dispatch of a service technician to Buyer's site. If the problem is covered under warranty, Seller will, at its discretion, promptly make the repairs or replacement at no charge to Buyer. Rebuilt parts may be used. On-site warranty service will be conducted during regular first shift hours, unless mutually agreed. Except for machine down calls, a premium may be charged for off-shift warranty service.
      
      This warranty is limited as follows:
      i.	This warranty is not transferable without the express written agreement of Seller.
      ii.	This warranty does not apply to software. Software is covered by a separate warranty which appears in Third Party Software License Agreement.
      iii.	This warranty does not cover consumable parts that wear in normal Product operation.
      iv.	This warranty does not cover defects or failure that result from any of the following factors:
      1.	Non compliance with Seller's installation, operation, calibration, maintenance and setup/adjustment specifications and procedures.
      2.	Changes that are made to the Products without written approval from Seller.
      3.	Misuse or abuse of the Product; 
      4.	Dust and other environmental factors.
      5.	The use of replacement parts other than those supplied or identified by Seller.
      
      The warranty on components not manufactured by Seller is limited to the warranty provided by the original manufactures of said components. Where there are major third-party items integrated into an assembly system, Seller will provide the warranty of those components at time of final delivery.
      
      For major third-party integrated items that are not manufactured by the Seller, the Buyer must depend upon the support policy of that particular company. The Seller is responsible to supply the support policies of those third-party companies.
      
      b.	Remedy. 
      If a nonconformity to the foregoing warranty is discovered in the Goods or Services during the applicable warranty period specified above, and written notice of such nonconformity is provided to Seller promptly after such discovery and within the applicable warranty period, Seller’s sole and exclusive obligation shall be, at its option, to either 
      1.	repair or replace the nonconforming portion of the Goods; 
      2.	ship repair or replacement parts to Buyer; 
      3.	re-perform the nonconforming Services; or 
      4.	refund the portion of the price applicable to the nonconforming portion of Goods or Services. If any portion of the Goods or Services so repaired, replaced or re-performed fails to conform to the foregoing warranty, and written notice of such nonconformity is provided to Seller promptly after discovery and within the original warranty period applicable to such Goods or Services or 30 days from completion of such repair, replacement or re-performance, whichever is later, Seller will repair or replace such nonconforming Goods or re-perform the Services. The applicable warranty period shall not otherwise be extended. 
      
      c.	Software. 
      Seller warrants that, except as specified below, the Software will, when properly installed, execute in accordance with Seller's published specifications. If a nonconformity to the foregoing warranty is discovered during the period ending one 90 days after the date of shipment, and written notice of such nonconformity is provided to Seller promptly after such discovery and within the warranty period, including a description of the nonconformity and complete information about the manner of its discovery, Seller shall correct the nonconformity by, at its option, either 
      (i)	modifying or making available to the Buyer instructions for modifying the Software; or
      (ii)	making available at Seller's facility necessary corrected or replacement programs. 
      
      d.	Seller shall have no obligation with respect to any nonconformities resulting from 
      (i)	unauthorized modification of the Software and/or 
      (ii)	Buyer-supplied software or interfacing. Seller does not warrant that the functions contained in the software will operate in combinations which may be selected for use by the Buyer, or that the software products are free from errors in the nature of what is commonly categorized by the computer industry as "bugs". 
      
      e.	Exceptions. Seller has no liability under this Section 10 for any of the following: 
      (i)	components that get consumed and replaced on a regular basis through normal use and operation of the Goods..; 
      (ii)	Buyer’s failure to provide Seller working access to the nonconforming Goods including disassembly and re-assembly of non-Seller supplied equipment, and for shipment to or from any repair facility – or the opportunity to examine the Goods - prior to expiration of the warranty period; 
      (iii)	improper installation, repair or alteration by Buyer or a third party not under Seller’s control and supervision; 
      (iv)	misuse, negligence or accident; 
      (v)	Buyer’s failure to meet its obligations in Section 8; 
      (vi)	failure as a result of materials provided by or a design specified by Buyer; 
      (vii)	failure as a result of Buyer not strictly adhering to maintenance schedules and procedures 
      (viii)	failure as a result of ordinary wear and tear; 
      (ix)	failure as a result of Buyer’s failure to comply with the law; 
      (x)	any failure submitted after expiration of the applicable warranty period; and/or 
      (xi)	if the Goods, Services and/or Software have not been paid for in full
      
      f.	Disclaimers. 
      THE FOREGOING WARRANTIES ARE EXCLUSIVE AND IN LIEU OF ALL OTHER WARRANTIES OF QUALITY AND PERFORMANCE, WHETHER WRITTEN, ORAL OR IMPLIED. ALL OTHER WARRANTIES INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR USAGE OF TRADE AND AGAINST INFRINGEMENT ARE HEREBY DISCLAIMED. THE REMEDIES STATED HEREIN CONSTITUTE BUYER’S EXCLUSIVE REMEDIES AND SELLER’S ENTIRE LIABILITY FOR ANY WARRANTY CLAIMS OR MATTERS.
      
      g.	Customer Assistance Policy. 
      The business of Seller is manufacturing and selling quality welding equipment, automated welding systems, consumables, and cutting equipment. Our challenge is to meet the needs of our customers and to exceed their expectations. On occasion, purchasers may ask Seller for information or advice about their use of the Goods, Services and/or Software. Seller’s employees respond to inquiries to the best of their ability based on information provided to them by the customers and the knowledge they may have concerning the application. Seller’s employees, however, are not in a position to verify the information provided or to evaluate the engineering requirements for the particular weldment or application. Accordingly, Seller does not warrant or guarantee or assume any liability with respect to such information or advice. Moreover, the provision of such information or advice does not create, expand, or alter any warranty on our Goods, Services and/or Software. Any express or implied warranty that might arise from the information or advice, including any implied warranty of merchantability or any warranty of fitness for Buyer’s particular purpose is specifically disclaimed. Seller is a responsive manufacturer, but the selection and use of specific Goods, Services and/or Software sold by Seller is solely within the control of and remains the sole responsibility of Buyer. Many variables beyond the control of Seller affect the results obtained in applying these types of fabrication methods and service requirements. 
      ` },
      { section: `Patent Indemnity`, body: `a.	By Seller. 
      Seller agrees to defend any suit, proceedings or counterclaim against Buyer for the infringement of any United States Letters Patent by: 
      1. any Goods manufactured by Seller, of whatever kind, or any parts thereof, made to Seller’s design or specifications, but only in the form, state or condition supplied under the Agreement; or 
      2. any use of such Goods manufactured by Seller where the Goods constitute a material part of any patented method of such patent and are not a staple article or commodity of commerce suitable for substantial non-infringing use. 
      Such defense is conditioned only if Seller is: 
      1. notified promptly in writing of any charges of infringement; 
      2. given authority to direct and control the defense of such charge or suit; and 
      3. furnished such information and assistance, at Seller’s expense, as may be necessary for such defense. 
      Seller shall pay all costs and damages awarded therein against Buyer. This Agreement does not apply to the combination of the Goods, Services and/or Software supplied under this Agreement with goods, services and/or software not supplied by Seller, nor to any process involving such combinations. If at any time, such Goods or any part thereof, or their use, are considered by the Seller to constitute infringement, Seller may, at its own expense: 
      1. procure for the Buyer the right to continue using such Goods; 
      2. modify them so they become non-infringing; or
      3. remove them and refund the purchase price and the transportation costs thereof, if any. The foregoing states the entire liability of the Seller for patent infringement by such Goods or their use. 
      b. By Buyer. 
      If Buyer supplies an order request to Seller for a product and/or its own specifications for the same, then Buyer represents that Buyer has ownership rights to, and/or have a license to have such product built for Buyer, and Buyer agrees to defend, indemnify and hold harmless Seller, its parent company, agents and/or affiliates from and against any claims, suits, proceedings (whether in court or out of court) of all types whatsoever against, and shall indemnify Seller, its parent company, directors, officers, employees, shareholders, affiliates and agents for all costs, damages, judgments, settlements and compromises (including incurred costs and attorneys’ fees) for the infringement or claimed infringement of any patent, trademark, service mark, trade secret, copyright, moral rights or other claims of violation of intellectual property anywhere in the world by: 
      1. Buyer’s request that Seller reproduce, manufacture, modify, utilize or incorporate Buyer’s product idea and/or specifications into this Agreement; or 
      2. any misrepresentation by Buyer that it had ownership rights and/or a license to have Goods built for it when such representation was not accurate and/or resulted in claims against Seller based upon Seller’s completion of a project for Buyer under such misrepresentation. 
      Buyer shall pay all costs, damages, judgments, settlements and compromises (including incurred costs and attorneys’ fees) arising out of or related to such claims, suits, proceedings (whether in court or out of court) against Seller, its parent company, directors, officers, employees, shareholders, affiliates and agents. 
      ` },
      { section: `Limitation of Liability`, body: `(A) IN NO EVENT SHALL SELLER, ITS PARENT, SUBSIDIARIES AND AFFILIATES BE LIABLE FOR SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, WHETHER IN CONTRACT, WARRANTY, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFITS OR REVENUE, LOSS OF USE OF THE GOODS, SERVICES AND/OR SOFTWARE OR ANY ASSOCIATED EQUIPMENT, COST OF CAPITAL, COST OF SUBSTITUTE EQUIPMENT, FACILITIES OR SERVICES, DOWNTIME COSTS, DELAYS, AND CLAIMS OF CUSTOMERS OF THE BUYER OR OTHER THIRD PARTIES FOR ANY DAMAGES. SELLER'S LIABILITY FOR ANY CLAIM WHETHER IN CONTRACT, WARRANTY, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE FOR ANY LOSS OR DAMAGE ARISING OUT OF, CONNECTED WITH, OR RESULTING FROM THIS AGREEMENT OR THE PERFORMANCE OR BREACH THEREOF, OR FROM THE DESIGN, MANUFACTURE, SALE, DELIVERY, RESALE, REPAIR, REPLACEMENT, INSTALLATION, TECHNICAL DIRECTION OF INSTALLATION, INSPECTION, OPERATION OR USE OF ANY GOODS AND/OR SOFTWARE COVERED BY OR FURNISHED UNDER THIS AGREEMENT, OR FROM ANY SERVICES RENDERED IN CONNECTION THEREWITH, SHALL IN NO CASE EXCEED THE PURCHASE PRICE ALLOCABLE TO THE GOODS, SERVICES AND/OR SOFTWARE OR PART THEREOF WHICH GIVES RISE TO THE CLAIM. 

      (B) ALL CAUSES OF ACTION AGAINST SELLER ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE PERFORMANCE OR BREACH HEREOF SHALL EXPIRE UNLESS BROUGHT WITHIN ONE YEAR OF THE TIME OF ACCRUAL THEREOF. (C) IN NO EVENT, REGARDLESS OF CAUSE, SHALL SELLER BE LIABLE FOR THE ACTS OR OMISSIONS OF BUYER OR THIRD PARTIES. 
      ` },
      { section: `Software Licenses`, body: `Seller. shall, to the extent possible, pass through to its customer, the benefits of any licenses to which Buyer. is entitled to by virtue of purchase of equipment, software, supplies or services from the manufacturers, subcontractors, suppliers, lessors or licensors of such items in connection with the provision of services detailed within this proposal.
      Seller and its Affiliates will make available to Buyer the source code and any other information the Buyer may need in order to revise, amend, enhance or augment the custom software program(s) used in connection with the programmable logic controller/computer used by Seller in connection with the Goods provided hereunder this agreement.
      ` },
      { section: `Data Security/Data Access`, body: `Some Goods and/or Software may require internet access for operation. Buyer is responsible for obtaining internet access and payment for all usage charges related thereto. If Seller or Buyer requires access to the other’s computer systems to perform tasks that fall under the scope of an Agreement, access shall be granted only to the extent necessary to fulfill any required tasks. Buyer represents that it has developed and implemented and covenants that it will maintain effective information security policies and procedures that include administrative, technical and physical safeguards designed to: 
      (a) ensure the confidentiality, security, integrity and availability of its computer systems and information; 
      (b) protect against threats or hazards to their computer systems and the confidentiality, security, integrity and availability of information; and 
      (c) protect against unauthorized access to its computer systems and information. Buyer shall promptly notify Seller of any breach of confidentiality or disclosure of confidential information, or a breach of information security policies or procedures, or unauthorized access to its computer systems. Notice shall be provided no later than twenty-four (24) hours upon discovery of a breach. 
      Buyer agrees that it shall be responsible for all acts and omissions with respect to the unauthorized access to its computer systems and information, including the acts and omissions of its employees, agents and independent contractors. Buyer agrees to indemnify and hold Seller harmless, its parent company, directors, officers, employees, shareholders, affiliates and agents from and against any and all third party claims of damages, liabilities, expenses, fines and losses of any type, including but not limited to reasonable attorneys’ fee, in connection with or arising out of, in whole or in part, of its or its representative’s breach of computer system security. 
      ` },
      { section: `Inventions and Information`, body: `All materials, and any inventions (whether or not patentable), works of authorship, trade secrets, ideas, concepts, trade names and trade or service marks created or prepared by Seller under this Agreement, together with any and all intellectual property rights therein (collectively “Inventions”), shall belong exclusively to Buyer. Seller hereby assigns the worldwide right, title and interest in and to the Inventions to Buyer. Buyer shall have the right, at its option and expense, to seek protection of the Inventions by obtaining patents, copyright registrations, and filings related to proprietary or intellectual property rights. Seller agrees to execute, and to cause its employees and/or agents to execute, such documents, applications, and conveyances and to supply information as Buyer shall request, in order to permit Buyer (at Buyer’s expense) to protect, perfect, register, record and maintain its rights in the Inventions and effective ownership of them throughout the world. These obligations survive the expiration or termination of this Agreement. Seller shall not, without Buyer’s prior written consent, copy or disclose such Inventions to a third party. Such Inventions shall be used by Seller solely for the operation or maintenance of the Goods, Services and/or Software and not for any other purpose, including the duplication thereof in whole or in part. ` },
      { section: `Confidentiality`, body: `“Confidential Information” means all information, know-how, trade secrets or other material disclosed by Buyer to Seller and Seller to Buyer. Both Buyer and Seller shall treat each other’s Confidential Information as confidential; shall not use such Confidential Information except in connection with the Agreement; shall not disclose such Confidential Information to any third party who has not executed an agreement to maintain the confidentiality of the Confidential Information with restrictions at least as restrictive as those set forth herein; and shall not reverse-engineer Seller’s Goods, Services and/or Software. All technical, business, sales, distribution channel, financial, marketing, pricing, planning, competitor information and the lists of customers who have purchased Goods from Seller are considered Seller’s Confidential Information. Confidential Information does not include information that is:
      i. generally known and available in the public domain; 
      ii. was known to recipient prior to the date of disclosure; 
      iii. was received from a third-party without any obligation of confidentiality; or 
      iv. was independently developed without reliance on Confidential Information. 
      Given the nature of the Confidential Information and the likely consequences of its unauthorized use or disclosure, monetary damages would not be an adequate remedy and both Seller and Buyer reserve the right to seek and obtain injunctive relief, in addition to any other remedy that may be available, in any proper forum.
      ` },
      { section: `Cancellation`, body: `a. Buyer may, at any time and for any reason cancel this Agreement within written 30-Day notice.
      b. If the Buyer, validly cancels the contract, the Buyer will be obliged to compensate the Seller for any costs for equipment, materials, labor etc incurred by the Seller in connection with making the offer and entering into the contract and the damage and/or loss arising from cancellation.
      ` },
      { section: `Termination for Default`, body: `(a) Either party may terminate this Agreement upon seven (7) days written notice should the other party fail substantially to perform in accordance with its terms through no fault of the party initiating the termination.
      (b) If contract is terminated, performance of the Agreement already received by the Buyer and the payment obligation of the Buyer in connection with it will remain. The amounts invoiced by the Seller for performance prior to or upon termination of the Agreement will be immediately due and payable after termination. Buyer agrees to pay any of the Seller’s costs, damages, attorneys’ fees and other expenses associated with Seller’s termination of any Agreement with Buyer pursuant to the terms of this section
      ` },
      { section: `Force Majeure`, body: `Seller shall not be in default for failure to perform and shall not be liable for loss, damage, detention or delay when prevented from doing so by causes beyond its reasonable control including but not limited to acts of war (declared or undeclared), Acts of God, fire, terrorism, sabotage, power, explosions, epidemics, civil disturbances, strike, labor difficulties, acts or omissions of any governmental authority, compliance with government laws or regulations, insurrection or riot, embargo, delays or shortages in transportation or inability to obtain necessary labor, raw materials, or manufacturing facilities from usual sources, equipment failure, or from defects or delays in the performance of its suppliers or subcontractors due to any of the foregoing enumerated causes. Upon the occurrence of any event or circumstance referenced above, Seller shall have the right to allocate Goods, Services and/or Software among its customers in its sole discretion. This Section supplements, and does not replace, any remedies available to Seller under applicable law. ` },
      { section: `Assignment`, body: `Buyer cannot assign this Agreement without Seller’s prior written consent. Seller can assign this Agreement. ` },
      { section: `Entire Agreement`, body: `The Agreement constitutes the entire agreement between Seller and Buyer with respect to the Goods, Services and/or Software covered by the Agreement, and supersedes any prior agreements, understandings, representations and quotations with respect thereto. No modification hereof will be of any effect unless mutually agreed to in writing. ` },
      { section: `Waiver`, body: `In the event of any default by Buyer, Seller may decline to ship Goods or Software or to provide Services. If Seller elects to continue shipping or otherwise fails to insist upon strict compliance with the Agreement, Seller’s actions will not constitute a waiver of Buyer’s default or any other existing or future default, or affect Seller’s legal remedies` },
      { section: `Severability`, body: `If any provision of this Agreement is held to be unlawful or unenforceable, the remaining provisions shall remain in effect. ` },
      { section: `Survival`, body: `Any provisions of this Agreement which, by their nature, extends beyond the completion, termination or expiration of any sale of Goods, Services and/or Software, will remain in effect until fulfilled.` },
      { section: `Compliance with Laws`, body: `Buyer will comply with all federal, state, local and foreign rules, regulations, ordinances and laws applicable to Buyer’s obligations under this Agreement and its operations or use of the Goods, Services and/or Software, including but not limited to those regarding safety, the environment, data protection, data privacy, conflict minerals, human trafficking/slavery, export/import, labor and anti-corruption. Nothing contained herein shall be construed as imposing responsibility or liability upon Seller for obtaining any permits, licenses or approvals from any agency required in connection with the supply, erection or operation of the Goods, Services and Software. In no event shall Seller be responsible for liability arising out of use of the Goods and/or Software in association with other equipment of Buyer, the alteration of the Goods and/or Software by any party other than Seller, or the violation of any laws relating to or caused by Buyer's design, location, operation, or maintenance of the Goods and/or Software. Buyer acknowledges that the Goods, Services and Software, if any, which are purchased or received under this Agreement may be subject to the export controls of the U.S. Export Administration Regulation, the U.S. Department of Treasury Office of Foreign Assets Control, the U.S. Department of State and other U.S. agencies, as well as the export control regulations of the European Union, the United Nations Security Council, and other foreign governments (“Export Control and Economic Sanctions Laws”). Buyer agrees that any export, resale, or re-export of Seller’s Goods shall be in compliance with all applicable Export Control and Economic Sanctions Laws, Unless licensed to do so, Buyer agrees that it will not: 
      (i) export, resell, re-export or transfer the Goods, Services and/or Software for end-uses that are prohibited by Export Control and Economic Sanctions Laws, including, but not limited to: maritime nuclear propulsion; nuclear, chemical and biological weapons; rocket, missile and unmanned air vehicle systems; and nuclear activities not in compliance with International Atomic Energy Agency (IAEA) safeguards; 
      (ii) export, resell, re-export or transfer any Goods, Services and/or Software to a customer that an entity or person that is listed, blocked or subject to sanctions under applicable Export Control and Economic Sanctions Laws, including entities that are owned 50% or more, directly or indirectly, individually or in the aggregate, by an individual or entity that is listed, blocked or subject to sanctions; or 
      (iii) (export, resell, re-export, transfer, or conduct transactions involving the Goods, Services, Software with or to entities or individuals in countries or regions subject to comprehensive sanctions, including: Crimea, Cuba, Iran, North Korea, Syria, and Sudan. 
      Further, none of the underlying information, software, or technology of the Goods, Services and/or Software may be transferred or otherwise exported or re-exported in violation of Export Control and Economic Sanctions Laws. Any diversion contrary to U.S. law or other applicable law is prohibited. By purchasing Goods from Seller, Buyer represents and warrants that Buyer is not located in, under the control of, or a national or resident of an Embargoed Country or Designated National. Buyer agrees to assume sole responsibility for obtaining licenses to export or re-export as may be required, and further represents and warrants that Buyer shall: 
      (iv) cooperate fully with Seller in any official or unofficial audit or inspection that relates to Export Control and Economic Sanctions Laws; and 
      (v) not export, re-export, divert, transfer, or disclose, directly or indirectly, any Goods, Services and/or Software sold hereunder or any related technical information, document, or material or direct products thereof to any country, entity, person or end-user so restricted by Export Control and Economic Sanctions Laws, as modified by time to time. 
      Seller and Buyer are committed to fair, honest and ethical business practices. Buyer acknowledges that Seller has adopted a Code of Corporate Conduct and Ethics (a copy of which is available on Seller’s website at www.lincolnelectric.com) and Buyer agrees to conduct itself in its dealings with or on behalf of Seller in a manner that is consistent with and facilitates compliance with Seller’s Code. 
      ` },
      { section: `Disputes and Arbitration`, body: `Any controversy or claim between Seller and Buyer or any controversy or claim otherwise arising out of or relating to the Terms of this Agreement shall be finally settled by binding arbitration in Rapid City, SD according to the rules of the American Arbitration Association. If any controversy or claim between the Buyer and Seller cannot be resolved via arbitration it will be handled according to Section 27` },
      { section: `Governing Law`, body: `VENUE AND CHOICE OF LAW:  This Agreement shall be construed in accordance with and governed by the laws of the State of South Dakota without regard to choice of law rules.  The parties agree that all actions and proceedings arising in connection with this Agreement shall be tried and litigated exclusively in the State and Federal courts located in the County of Pennington, State of South Dakota.  The aforementioned choice of venue is intended by the parties to be mandatory and not permissive in nature, thereby precluding the possibility of litigation between the parties with respect to or arising out of this Agreement in any jurisdiction other than that specified in this section.  Each party waives any right to assert the doctrine of forum non conveniens or similar doctrine or to object to venue with respect to any proceeding brought in accordance with this section, and stipulate that the State and Federal Courts located in the County of Pennington, State of South Dakota, shall have in personal jurisdiction and venue over each of them for the purpose of litigating any dispute, controversy, or proceeding arising out of or related to this Agreement` },      
    ]
  }
};

export default TermsConditionsStore;